import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Cards from '../components/Cards';

const ResourcesPage = ({
  data: {
    datoCmsBannerImage: { resourcesBannerImage },
    allDatoCmsNewsInsight: { nodes: newsInsightNodes },
    allDatoCmsDemoWebinar: { nodes: demoWebinarNodes },
    allDatoCmsCaseStudy: { nodes: caseStudyNodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="Resources | Barcanet" />
    <main>
      <Banner heading="Resources" image={resourcesBannerImage} />
      <Cards
        heading="News &amp; Insights"
        linkText="View more"
        linkUrl="/resources/insights/"
        items={newsInsightNodes}
        baseSlug="/resources/insights/"
      />
      <Cards
        heading="Demos &amp; Webinars"
        linkText="View more"
        linkUrl="/resources/demos-webinars/"
        items={demoWebinarNodes}
        baseSlug="/resources/demos-webinars/"
      />
      <Cards
        heading="Case Studies"
        linkText="View more"
        linkUrl="/resources/case-studies/"
        items={caseStudyNodes}
        baseSlug="/resources/case-studies/"
      />
    </main>
  </Layout>
);

export const ResourcesPageQuery = graphql`
  query ResourcesPageQuery {
    datoCmsBannerImage {
      resourcesBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsNewsInsight(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 4
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        meta {
          firstPublishedAt(formatString: "DD/MM/YYYY")
        }
      }
    }
    allDatoCmsDemoWebinar(sort: { fields: position }, limit: 4) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
      }
    }
    allDatoCmsCaseStudy(sort: { fields: position }, limit: 4) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
      }
    }
  }
`;

export default ResourcesPage;
